export default {
    apiBase: 'https://api.sandbox.rec.barcelona',
    clientID: '1_23zebs1ciqsk088s4wckckgwkcogo8ws8os48osc40s0s8ss0k',
    clientSecret: '2zspa4vc6ask0wk0kcso0sowg0w088k00k00gkcs8ggs0g400g',
    lng: "es",
    type: 'whitelabel',
    crypto_currency: 'QBIT',
    pollingInterval: 15e3,
    recScaleFactor: Math.pow(10, 8),
    version: '1.0.0',
}