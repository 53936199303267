import { Fetcher } from './Fetcher';
import { AuthProvider } from './AuthProvider';
import config from '../config';
import { ContentTypeJson } from './ConstHeaders';

export interface PaymentArgs {
  concept: string;
  address: string;
  amount: number;
  pin: string;
}

export const ApiProvider = {
  getHeaders(extra?: { [key: string]: string }) {
    return new Headers({
      authorization: 'Bearer ' + AuthProvider.getToken(),
      ...extra,
    });
  },
  getPayment(paymentId: string) {
    return Fetcher.get(`/public/v3/payment_orders/${paymentId}`).then(
      (resp) => {
        resp.scaledAmount = +resp.amount / config.recScaleFactor;
        return resp;
      }
    );
  },
  getAccount() {
    const headers = ApiProvider.getHeaders();

    return Fetcher.get(`/user/v1/account`, { headers });
  },
  getAccounts() {
    const headers = ApiProvider.getHeaders();

    return Fetcher.get(`/user/v1/companies`, { headers });
  },
  selectAccount(group_id: string) {
    const headers = ApiProvider.getHeaders(ContentTypeJson);
    const body = JSON.stringify({ group_id });

    return Fetcher.put(`/user/v1/activegroup`, { headers, body });
  },
  makePayment(paymentData: PaymentArgs) {
    const currency = config.crypto_currency ? config.crypto_currency.toLowerCase() : 'rec';
    const headers = ApiProvider.getHeaders(ContentTypeJson);
    const body = JSON.stringify(paymentData);

    return Fetcher.post(`/methods/v3/out/${currency}`, { headers, body });
  },
};
