import React from 'react';
import { Avatar, useTheme } from '@material-ui/core';
import { useStyles } from '../common-styles';
import config from '../config';

export const AccountPreview = ({
  group,
  trimText = false,
  showBalance = false,
}: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const hasWallets = group.wallets && group.wallets.length;
  const currency = config.crypto_currency ?? 'REC';

  const balance = (hasWallets
    ? (group.wallets.find((el: any) => el.currency === currency) || { balance: 0 }).balance / config.recScaleFactor
    : 0
  ).toFixed(2);

  return (
    <div className="row center">
      <Avatar
        data-testid="avatar"
        className={classes.avatarSmall}
        src={group.company_image || group.public_image}
      />
      <b
        data-testid="name"
        className={'small ' + (trimText ? 'overflow-ellipsis' : '')}
        style= {{marginRight: '5px'}}
      >
        {group.name}
      </b>
      {showBalance && <p style={{color: theme.palette.primary.main}} data-testid="balance">{balance} R</p>}
    </div>
  );
};
